import RentalRedirect from 'app/components/Core/Rental/Redirect'
import Restricted from 'app/components/UI-Elements/General/Restricted'
import { RestrictionMode } from 'app/constants/GlobalTypes'
import { useCurrentContactOrganization } from 'app/lib/hooks/useCurrent'
import NotAuthorized from 'app/pages/Error/Views/NotAuthorized'
import { Navigate } from 'react-router-dom'

import { Routes } from './Routes'

type Props = {
  allowFor?: RoleType[]
  restrictionMode?: RestrictionMode
  children?: React.ReactNode
}

const RestrictedRoute: React.FC<Props> = ({
  allowFor,
  restrictionMode = 'NotAuthorized',
  children
}) => {
  const organization = useCurrentContactOrganization()

  const renderOnHide = () => {
    if (restrictionMode === 'RedirectToTransactional') {
      return <RentalRedirect />
    } else if (organization.role === 'guest') {
      return <Navigate to={Routes.Error.collection().role_not_authorized} />
    } else {
      return <NotAuthorized />
    }
  }

  return organization ? (
    <Restricted allowFor={allowFor} renderOnHide={renderOnHide}>
      <>{children}</>
    </Restricted>
  ) : (
    <>{children}</>
  )
}

export default RestrictedRoute
